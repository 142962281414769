html,
body {
    transition: all ease-in 0.1s;
    font-family: "Source Sans Pro", sans-serif !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    font-weight: 400;
    font-style: normal;
    color: #333;
}
html {
    scrollbar-width: auto;
    scrollbar-color: #d6d6d6;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 9px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(63, 39, 75, 0.801);
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d6d6d6;
        border-radius: 10px;
        border: 1px solid #ffffff;
    }
}
.dropdown-item.active,
.dropdown-item:active {
    background: #009750;
}
.dropdown-item {
    i,
    svg {
        color: #a8a8b1;
        margin-right: 5px;
    }
}
.btn-egg {
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px 0 #ccc;
    letter-spacing: 0.05rem;
    position: relative;
    background: #009750;
    color: #fff !important;
    width: auto;
    min-width: 150px;
    min-height: 45px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    border-radius: 0.3rem;
    z-index: 1;
    &:hover,
    &:focus,
    &:focus-visible {
        box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
    }
}
.btn-redirect {
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px 0 #ccc;
    letter-spacing: 0.05rem;
    position: relative;
    background: #009750;
    color: #fff !important;
    width: auto;
    min-width: 150px;
    min-height: 45px;
    overflow: hidden;
    max-width: 300px;
    transition: 0.3s ease-in-out;
    z-index: 1;
    padding: 10px 20px;
    border-radius: calc(60px * 1.5);
    text-align: center;
    &:hover,
    &:focus,
    &:focus-visible {
        text-decoration: none;
        box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
    }
    &.outline {
        background: transparent;
        border: 2px solid #009750;
        box-shadow: none;
        &:hover {
            border-color: #fff;
            background-color: #009750;
            box-shadow: 0 5px 18px rgba(255, 255, 255, 0.2), 0 10px 12px rgba(255, 255, 255, 0.2);
        }
    }
}
.cursor-pointer {
    cursor: pointer;
}

.section {
    position: relative;
    padding-top: 100px;
    padding-bottom: 80px;
    position: relative;
    .mini-title {
        font-size: 1.125rem;
        font-weight: 600;
        text-transform: capitalize;
        text-align: justify;
        line-height: 120%;
    }
    h2.title {
        font-size: clamp(1.2rem, 3vw, 1.8rem);
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 15px;
    }
}
[data-aos] {
    pointer-events: all;
}
.text-last-banner {
    width: 100%;
    max-width: fit-content;
    position: relative;
    z-index: 300;
    h2.title {
        font-size: 2.725rem;
        font-weight: 400;
        line-height: 3.0875rem;
        margin-bottom: 24px;
        text-align: initial;
    }
    .detail {
        font-size: 1.125rem;
        text-align: initial;
        margin-bottom: 0;
    }
    .button-section {
        display: flex;
        flex-direction: column;
        .button {
            letter-spacing: 0.05rem;
            position: relative;
            color: #fff !important;
            min-width: 150px;
            overflow: hidden;
            transition: 0.3s ease-in-out;
            border-radius: 0.3rem;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 10px;
            max-width: 300px;

            &.btn-register {
                background: #009750;
                padding: 15px 10px;
                min-height: 55px;
                border-radius: calc(55px * 1.5);
                &:hover,
                &:focus,
                &:focus-visible {
                    box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}
