// Your variable overrides
$body-bg: #fff;
$body-color: #111;


@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";
@import url("./font.scss");
@import url("./customerVariables.scss");
@import url("./theme.scss");
@import url("./media.scss");

html,
html * {
    font-family: "Source Sans Pro", sans-serif !important;
}
