

































































































































































footer {
  background-image: url(~@/assets/images/background/bgfooter.jpg);
  background-size: cover;
  min-height: 275px;
  -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
  box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
  padding: 0rem 80px 0rem;
  .footer-one {
    .menu-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .logo {
        img {
          width: 100%;
          max-width: 180px;
          height: auto;
        }
      }
      .social-footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        margin-bottom: 0;
        a {
          width: 30px;
          height: 30px;
          margin-right: 15px;
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .menu-footer {
      padding: 0 22px;
      margin-bottom: 0;
      li {
        padding: 10px 0;
        &:first-child {
          margin-bottom: 10px;
          a {
            font-size: 17px;
            font-weight: 700;
          }
        }
        a {
          color: #fff;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
  .footer-two {
    padding: 40px 0;
    border-top: 2px solid rgba($color: #ffffff, $alpha: 0.4);
    color: #fff;

    @media (max-width: 1440px) {
      padding: 20px 0 15px;
    }
  }
  .footer-three {
    padding: 10px 0;
    border-top: 2px solid rgba($color: #ffffff, $alpha: 0.4);
    color: #fff;
    .warning-title {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 30px;
        margin-right: 5px;
      }
      img.two {
        width: 30px;
        margin-left: 5px;
      }
      span {
        color: #ffc107;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .menu-policy {
      margin-bottom: 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        padding-right: 40px;
        margin-bottom: 10px;

        a {
          color: #009750;
          font-size: 17px;
          font-weight: 600;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .footer-one {
      .menu-footer {
        padding: 0 22px;
        margin-bottom: 0;
        li {
          padding: 0 0 10px;
          &:first-child {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding: 2rem 15px;
  }
}
